<template>
  <img alt="Vue logo" src="./assets/gamecellasia.png" />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
}

html {
  background-color: #0b1219;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  width: 100%;
  height: auto;
}
</style>
